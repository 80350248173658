:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-1{flex-shrink:1;}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._fg-1{flex-grow:1;}
:root ._w-10037{width:100%;}
:root ._btlr-t-radius-4{border-top-left-radius:var(--t-radius-4);}
:root ._btrr-t-radius-4{border-top-right-radius:var(--t-radius-4);}
:root ._bbrr-t-radius-4{border-bottom-right-radius:var(--t-radius-4);}
:root ._bblr-t-radius-4{border-bottom-left-radius:var(--t-radius-4);}
:root ._fs-0{flex-shrink:0;}
:root ._mt-t-space-4{margin-top:var(--t-space-4);}
:root ._mr-t-space-4{margin-right:var(--t-space-4);}
:root ._mb-t-space-4{margin-bottom:var(--t-space-4);}
:root ._ml-t-space-4{margin-left:var(--t-space-4);}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._mt-_gtSm_t-space-8{margin-top:var(--t-space-8);}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._mr-_gtSm_t-space-8{margin-right:var(--t-space-8);}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._mb-_gtSm_t-space-8{margin-bottom:var(--t-space-8);}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._ml-_gtSm_t-space-8{margin-left:var(--t-space-8);}}
:root ._gap-t-space-2{gap:var(--t-space-2);}
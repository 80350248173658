:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._gap-t-space-1{gap:var(--t-space-1);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._col-onSurface--1130999482{color:var(--onSurface--neutral--default);}
:root ._fow-600{font-weight:600;}
:root ._ls-f-letterSpa1595118806{letter-spacing:var(--f-letterSpacing-0--8);}
:root ._fos-f-size-20{font-size:var(--f-size-20);}
:root ._lh-f-lineHeigh3500603{line-height:var(--f-lineHeight-24);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._h-t-size-3{height:var(--t-size-3);}
:root ._jc-center{justify-content:center;}
:root ._w-10037{width:100%;}
:root ._h-t-size-2{height:var(--t-size-2);}
:root ._btlr-t-radius-fu3456{border-top-left-radius:var(--t-radius-full);}
:root ._btrr-t-radius-fu3456{border-top-right-radius:var(--t-radius-full);}
:root ._bbrr-t-radius-fu3456{border-bottom-right-radius:var(--t-radius-full);}
:root ._bblr-t-radius-fu3456{border-bottom-left-radius:var(--t-radius-full);}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._pos-absolute{position:absolute;}
:root ._h-10037{height:100%;}
:root ._l-0px{left:0px;}
:root ._r-0px{right:0px;}
:root ._mr-t-space-4{margin-right:var(--t-space-4);}
:root ._ml-t-space-4{margin-left:var(--t-space-4);}
:root ._bg-onSurface--1130999482{background-color:var(--onSurface--neutral--default);}
:root ._w-t-size-1{width:var(--t-size-1);}
:root ._fd-row{flex-direction:row;}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-1{flex-shrink:1;}
:root ._gap-t-space-4{gap:var(--t-space-4);}
:root ._pt-t-space-4{padding-top:var(--t-space-4);}
:root ._pr-t-space-4{padding-right:var(--t-space-4);}
:root ._pb-t-space-4{padding-bottom:var(--t-space-4);}
:root ._pl-t-space-4{padding-left:var(--t-space-4);}
:root ._fg-1{flex-grow:1;}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._pt-_gtSm_t-space-6{padding-top:var(--t-space-6);}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._pr-_gtSm_t-space-6{padding-right:var(--t-space-6);}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._pb-_gtSm_t-space-6{padding-bottom:var(--t-space-6);}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._pl-_gtSm_t-space-6{padding-left:var(--t-space-6);}}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._bg-surface--ne483693245{background-color:var(--surface--neutral--default);}
@media (min-width: 601px){:root:root:root:root:root:root:root:root ._pr-_gtXxs_t-space-4{padding-right:var(--t-space-4);}}
@media (min-width: 601px){:root:root:root:root:root:root:root:root ._pl-_gtXxs_t-space-4{padding-left:var(--t-space-4);}}
:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pl-t-space-2{padding-left:var(--t-space-2);}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._fd-row{flex-direction:row;}
:root ._gap-t-space-2{gap:var(--t-space-2);}
:root ._fs-1{flex-shrink:1;}
:root ._fg-1{flex-grow:1;}
:root ._w-10037{width:100%;}
:root ._aspectRatio-2--355{aspect-ratio:2.355;}
:root ._dsp-flex{display:flex;}
:root ._fd-row{flex-direction:row;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._w-10037{width:100%;}
:root ._pos-relative{position:relative;}
:root ._ai-center{align-items:center;}
:root ._jc-flex-start{justify-content:flex-start;}
:root ._pt-t-space-4{padding-top:var(--t-space-4);}
:root ._pr-t-space-4{padding-right:var(--t-space-4);}
:root ._pb-t-space-4{padding-bottom:var(--t-space-4);}
:root ._pl-t-space-4{padding-left:var(--t-space-4);}
:root ._gap-t-space-4{gap:var(--t-space-4);}
:root ._jc-space-betwe3241{justify-content:space-between;}
@media (min-width: 601px){:root:root:root:root:root:root:root:root ._pt-_gtXxs_t-space-6{padding-top:var(--t-space-6);}}
@media (min-width: 601px){:root:root:root:root:root:root:root:root ._pr-_gtXxs_t-space-6{padding-right:var(--t-space-6);}}
@media (min-width: 601px){:root:root:root:root:root:root:root:root ._pb-_gtXxs_t-space-6{padding-bottom:var(--t-space-6);}}
@media (min-width: 601px){:root:root:root:root:root:root:root:root ._pl-_gtXxs_t-space-6{padding-left:var(--t-space-6);}}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._dsp-none{display:none;}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._dsp-_gtSm_flex{display:flex;}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._dsp-_gtSm_none{display:none;}}
:root ._dsp-inline{display:inline;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-onSurface--1130999482{color:var(--onSurface--neutral--default);}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-600{font-weight:600;}
:root ._ls-f-letterSpa779312659{letter-spacing:var(--f-letterSpacing-16);}
:root ._fos-f-size-24{font-size:var(--f-size-24);}
:root ._lh-f-lineHeigh3500632{line-height:var(--f-lineHeight-32);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pt-t-space-10{padding-top:var(--t-space-10);}
:root ._pb-t-space-10{padding-bottom:var(--t-space-10);}
:root ._w-10037{width:100%;}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._dsp-_gtXs_none{display:none;}}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pos-relative{position:relative;}
:root ._mih-250px{min-height:250px;}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._mih-_gtSm_250px{min-height:250px;}}
:root ._mih-0px{min-height:0px;}
:root ._h-250px{height:250px;}
:root ._w-444px{width:444px;}
:root ._pos-absolute{position:absolute;}
:root ._b-0px{bottom:0px;}
:root ._r-0px{right:0px;}
:root ._transform-translateY41917575534{transform:translateY(40px) translateX(40px);}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._h-_gtSm_250px{height:250px;}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._w-_gtSm_440px{width:440px;}}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-onSurface--1130999482{color:var(--onSurface--neutral--default);}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-600{font-weight:600;}
:root ._ls-f-letterSpa779312659{letter-spacing:var(--f-letterSpacing-16);}
:root ._fos-f-size-24{font-size:var(--f-size-24);}
:root ._lh-f-lineHeigh3500632{line-height:var(--f-lineHeight-32);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._fow-400{font-weight:400;}
:root ._fos-f-size-16{font-size:var(--f-size-16);}
:root ._lh-f-lineHeigh3500601{line-height:var(--f-lineHeight-22);}
:root ._gap-t-space-4{gap:var(--t-space-4);}
.is_DropdownStyledItem:hover + .is_DropdownStyledItem,
.is_DropdownStyledItem:hover + a .is_DropdownStyledItem {
  border-color: transparent;
}

.is_DropdownStyledItem.active + .is_DropdownStyledItem,
.is_DropdownStyledItem:active + a .is_DropdownStyledItem {
  border-color: transparent;
}

.is_DropdownStyledItem:first-child {
  border-color: transparent;
}

:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-1{flex-shrink:1;}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._fg-1{flex-grow:1;}
:root ._w-10037{width:100%;}
:root ._fs-0{flex-shrink:0;}
:root ._als-stretch{align-self:stretch;}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._als-_gtSm_baseline{align-self:baseline;}}
:root ._pos-absolute{position:absolute;}
:root ._t-0px{top:0px;}
:root ._l-0px{left:0px;}
:root ._r-0px{right:0px;}
:root ._b-0px{bottom:0px;}
:root ._h-10037{height:100%;}
:root ._mt-auto{margin-top:auto;}
:root ._ai-flex-start{align-items:flex-start;}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-600{font-weight:600;}
:root ._ls-f-letterSpa779312659{letter-spacing:var(--f-letterSpacing-16);}
:root ._fos-f-size-24{font-size:var(--f-size-24);}
:root ._lh-f-lineHeigh3500632{line-height:var(--f-lineHeight-32);}
:root ._col-white{color:white;}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
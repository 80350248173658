:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pos-relative{position:relative;}
:root ._pb-56--2537{padding-bottom:56.25%;}
:root ._h-0px{height:0px;}
:root ._mt-t-space-4{margin-top:var(--t-space-4);}
:root ._mb-t-space-4{margin-bottom:var(--t-space-4);}
:root ._mr-0px{margin-right:0px;}
:root ._ml-0px{margin-left:0px;}
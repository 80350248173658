:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._gap-t-space-4{gap:var(--t-space-4);}
:root ._w-10037{width:100%;}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._w-_gtSm_6037{width:60%;}}
@media (min-width: 1281px){:root:root:root:root:root:root:root:root:root:root:root ._w-_gtMd_4037{width:40%;}}
@media (min-width: 1281px){:root:root:root:root:root:root:root:root:root:root:root ._pt-_gtMd_t-space-4{padding-top:var(--t-space-4);}}
@media (min-width: 1281px){:root:root:root:root:root:root:root:root:root:root:root ._pr-_gtMd_t-space-4{padding-right:var(--t-space-4);}}
@media (min-width: 1281px){:root:root:root:root:root:root:root:root:root:root:root ._pb-_gtMd_t-space-4{padding-bottom:var(--t-space-4);}}
@media (min-width: 1281px){:root:root:root:root:root:root:root:root:root:root:root ._pl-_gtMd_t-space-4{padding-left:var(--t-space-4);}}
:root ._ai-stretch{align-items:stretch;}
:root ._zi-1{z-index:1;}
:root ._h-160px{height:160px;}
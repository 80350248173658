:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._ai-flex-end{align-items:flex-end;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-row{flex-direction:row;}
:root ._w-10037{width:100%;}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._pr-_gtXs_t-space-4{padding-right:var(--t-space-4);}}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root ._pl-_gtXs_t-space-4{padding-left:var(--t-space-4);}}
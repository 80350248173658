:root ._dsp-flex{display:flex;}
:root ._fd-row{flex-direction:row;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pt-t-space-6{padding-top:var(--t-space-6);}
:root ._pb-t-space-6{padding-bottom:var(--t-space-6);}
:root ._pr-t-space-4{padding-right:var(--t-space-4);}
:root ._pl-t-space-4{padding-left:var(--t-space-4);}
:root ._btw-0px{border-top-width:0px;}
:root ._brw-0px{border-right-width:0px;}
:root ._bbw-0px{border-bottom-width:0px;}
:root ._blw-0px{border-left-width:0px;}
:root ._bg-transparent{background-color:transparent;}
:root ._cur-pointer{cursor:pointer;}
:root ._ai-center{align-items:center;}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
@media (min-width: 601px){:root:root:root:root:root:root:root:root ._pr-_gtXxs_t-space-6{padding-right:var(--t-space-6);}}
@media (min-width: 601px){:root:root:root:root:root:root:root:root ._pl-_gtXxs_t-space-6{padding-left:var(--t-space-6);}}
@media (hover) {:root  ._bg-0hover-onSurface--1936048705:hover{background-color:var(--onSurface--neutral--zebra) !important;}}
:root:root  ._bg-0active-onSurface--288913258:active{background-color:var(--onSurface--neutral--zebraAlt) !important;}
:root ._gap-t-space-4{gap:var(--t-space-4);}
:root ._fd-column{flex-direction:column;}
:root ._jc-center{justify-content:center;}
:root ._btlr-t-radius-fu3456{border-top-left-radius:var(--t-radius-full);}
:root ._btrr-t-radius-fu3456{border-top-right-radius:var(--t-radius-full);}
:root ._bbrr-t-radius-fu3456{border-bottom-right-radius:var(--t-radius-full);}
:root ._bblr-t-radius-fu3456{border-bottom-left-radius:var(--t-radius-full);}
:root ._w-t-size-8{width:var(--t-size-8);}
:root ._h-t-size-8{height:var(--t-size-8);}
:root ._bg-onSurface--288913258{background-color:var(--onSurface--neutral--zebraAlt);}
:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pr-t-space-6{padding-right:var(--t-space-6);}
:root ._pl-t-space-6{padding-left:var(--t-space-6);}
:root ._pt-t-space-2{padding-top:var(--t-space-2);}
:root ._pb-t-space-6{padding-bottom:var(--t-space-6);}
:root ._gap-t-space-6{gap:var(--t-space-6);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-onSurface--1130999482{color:var(--onSurface--neutral--default);}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-600{font-weight:600;}
:root ._ls-f-letterSpa779312659{letter-spacing:var(--f-letterSpacing-16);}
:root ._fos-f-size-18{font-size:var(--f-size-18);}
:root ._lh-f-lineHeigh3500599{line-height:var(--f-lineHeight-20);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._gap-t-space-4{gap:var(--t-space-4);}
:root ._ai-center{align-items:center;}
:root ._gap-t-space-1{gap:var(--t-space-1);}
@media (min-width: 601px){:root:root:root:root:root:root:root:root ._fd-_gtXxs_row{flex-direction:row;}}
:root ._fos-f-size-24{font-size:var(--f-size-24);}
:root ._lh-f-lineHeigh3500632{line-height:var(--f-lineHeight-32);}
:root ._fd-row{flex-direction:row;}
:root ._gap-t-space-2{gap:var(--t-space-2);}
:root ._jc-center{justify-content:center;}
:root ._fow-400{font-weight:400;}
:root ._fos-f-size-14{font-size:var(--f-size-14);}
:root ._lh-f-lineHeigh3500576{line-height:var(--f-lineHeight-18);}
:root ._btlr-t-radius-1{border-top-left-radius:var(--t-radius-1);}
:root ._btrr-t-radius-1{border-top-right-radius:var(--t-radius-1);}
:root ._bbrr-t-radius-1{border-bottom-right-radius:var(--t-radius-1);}
:root ._bblr-t-radius-1{border-bottom-left-radius:var(--t-radius-1);}
:root ._fs-1{flex-shrink:1;}
:root ._fg-1{flex-grow:1;}
:root ._ai-flex-start{align-items:flex-start;}
@media (min-width: 601px){:root:root:root:root:root:root:root:root ._pl-_gtXxs_t-space-4{padding-left:var(--t-space-4);}}
:root ._fos-f-size-16{font-size:var(--f-size-16);}
:root ._lh-f-lineHeigh3500601{line-height:var(--f-lineHeight-22);}
:root ._fos-f-size-12{font-size:var(--f-size-12);}
:root ._lh-f-lineHeigh3500574{line-height:var(--f-lineHeight-16);}
:root ._btlr-t-radius-4{border-top-left-radius:var(--t-radius-4);}
:root ._btrr-t-radius-4{border-top-right-radius:var(--t-radius-4);}
:root ._bbrr-t-radius-4{border-bottom-right-radius:var(--t-radius-4);}
:root ._bblr-t-radius-4{border-bottom-left-radius:var(--t-radius-4);}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._btw-1px{border-top-width:1px;}
:root ._brw-1px{border-right-width:1px;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._blw-1px{border-left-width:1px;}
:root ._btc-onSurface--1968954642{border-top-color:var(--onSurface--neutral--outlineAlt);}
:root ._brc-onSurface--1968954642{border-right-color:var(--onSurface--neutral--outlineAlt);}
:root ._bbc-onSurface--1968954642{border-bottom-color:var(--onSurface--neutral--outlineAlt);}
:root ._blc-onSurface--1968954642{border-left-color:var(--onSurface--neutral--outlineAlt);}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._bg-onSurface--1936048705{background-color:var(--onSurface--neutral--zebra);}
:root ._pt-t-space-6{padding-top:var(--t-space-6);}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._ls-f-letterSpa1360334206{letter-spacing:var(--f-letterSpacing-0);}
:root ._w-10037{width:100%;}
:root ._bg-onSurface--1130999482{background-color:var(--onSurface--neutral--default);}
:root ._btlr-t-radius-fu3456{border-top-left-radius:var(--t-radius-full);}
:root ._btrr-t-radius-fu3456{border-top-right-radius:var(--t-radius-full);}
:root ._bbrr-t-radius-fu3456{border-bottom-right-radius:var(--t-radius-full);}
:root ._bblr-t-radius-fu3456{border-bottom-left-radius:var(--t-radius-full);}
:root ._w-20px{width:20px;}
:root ._h-20px{height:20px;}
:root ._col-onSurface--819480527{color:var(--onSurface--neutral--defaultInverted);}
:root ._maw-7037{max-width:70%;}
@media (min-width: 601px){:root:root:root:root:root:root:root:root ._maw-_gtXxs_10037{max-width:100%;}}
:root ._jc-flex-start{justify-content:flex-start;}
:root ._maw-10037{max-width:100%;}
:root ._whiteSpace-nowrap{white-space:nowrap;}
:root ._lh-18px{line-height:18px;}
:root ._textOverflow-ellipsis{text-overflow:ellipsis;}
:root ._bg-onSurface--288913258{background-color:var(--onSurface--neutral--zebraAlt);}
:root ._als-center{align-self:center;}
:root ._bg-surface--po304580953{background-color:var(--surface--positive--default);}
:root ._ls-f-letterSpa1595118806{letter-spacing:var(--f-letterSpacing-0--8);}
:root ._fos-f-size-20{font-size:var(--f-size-20);}
:root ._lh-f-lineHeigh3500603{line-height:var(--f-lineHeight-24);}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._fd-_gtSm_row{flex-direction:row;}}
:root ._fow-bold{font-weight:bold;}
:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-row{flex-direction:row;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._fs-1{flex-shrink:1;}
:root ._fg-1{flex-grow:1;}
:root ._bg-onSurface--1463374370{background-color:var(--onSurface--primary--decorative);}
:root ._btlr-t-radius-tr3728{border-top-left-radius:var(--t-radius-true);}
:root ._btrr-t-radius-tr3728{border-top-right-radius:var(--t-radius-true);}
:root ._bbrr-t-radius-tr3728{border-bottom-right-radius:var(--t-radius-true);}
:root ._bblr-t-radius-tr3728{border-bottom-left-radius:var(--t-radius-true);}
:root ._h-t-size-2{height:var(--t-size-2);}
:root ._w-t-size-2{width:var(--t-size-2);}
:root ._mr-t-space-1{margin-right:var(--t-space-1);}
:root ._dsp-inline-flex{display:inline-flex;}
:root ._bg-onSurface--1218670400{background-color:var(--onSurface--secondary--low);}
:root ._fd-row{flex-direction:row;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._dsp-flex{display:flex;}
:root ._pt-t-space-2{padding-top:var(--t-space-2);}
:root ._pb-t-space-2{padding-bottom:var(--t-space-2);}
:root ._pr-t-space-3{padding-right:var(--t-space-3);}
:root ._pl-t-space-3{padding-left:var(--t-space-3);}
:root ._w-10037{width:100%;}
:root ._ai-center{align-items:center;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._pr-_gtSm_t-space-2{padding-right:var(--t-space-2);}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._pl-_gtSm_t-space-2{padding-left:var(--t-space-2);}}
:root ._pt-t-space-6{padding-top:var(--t-space-6);}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._fd-_gtSm_row{flex-direction:row;}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._maw-_gtSm_1120px{max-width:1120px;}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._gap-_gtSm_t-space-6{gap:var(--t-space-6);}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._mr-_gtSm_auto{margin-right:auto;}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._ml-_gtSm_auto{margin-left:auto;}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._ai-_gtSm_flex-start{align-items:flex-start;}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._pr-_gtSm_t-space-4{padding-right:var(--t-space-4);}}
@media (min-width: 1025px){:root:root:root:root:root:root:root:root:root:root ._pl-_gtSm_t-space-4{padding-left:var(--t-space-4);}}
@media (min-width: 1281px){:root:root:root:root:root:root:root:root:root:root:root ._gap-_gtMd_t-space-10{gap:var(--t-space-10);}}
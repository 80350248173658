@font-face {
  font-family: 'Calibre';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src:
    local('Calibre Light'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/calibre-light.woff2') format('woff2'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Light.woff') format('woff'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Calibre';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src:
    local('Calibre Regular'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/calibre-regular.woff2') format('woff2'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Regular.woff') format('woff'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Calibre';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src:
    local('Calibre Medium'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/calibre-medium.woff2') format('woff2'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Medium.woff') format('woff'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Calibre';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src:
    local('Calibre Semiboid'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/calibre-semibold.woff2') format('woff2'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Semibold.woff') format('woff'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Calibre';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src:
    local('Calibre Bold'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/calibre-bold.woff2') format('woff2'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Bold.woff') format('woff'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'DancingScript-Regular';
  font-style: normal;
  font-display: block;
  src: url('https://cdn.arrivedhomes.com/fonts/dancing-script/DancingScript-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Calibre-Light';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src:
    local('Calibre Light'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/calibre-light.woff2') format('woff2'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Light.woff') format('woff'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Calibre-Regular';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src:
    local('Calibre Regular'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/calibre-regular.woff2') format('woff2'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Regular.woff') format('woff'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Calibre-Medium';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src:
    local('Calibre Medium'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/calibre-medium.woff2') format('woff2'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Medium.woff') format('woff'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Calibre-Semibold';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src:
    local('Calibre Semiboid'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/calibre-semibold.woff2') format('woff2'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Semibold.woff') format('woff'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Calibre-Bold';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src:
    local('Calibre Bold'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/calibre-bold.woff2') format('woff2'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Bold.woff') format('woff'),
    url('https://cdn.arrivedhomes.com/fonts/calibre/Calibre-Bold.ttf') format('truetype');
}

:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pos-sticky{position:sticky;}
:root ._t-0px{top:0px;}
:root ._zi-t-zIndex-st3226632{z-index:var(--t-zIndex-sticky);}
:root ._bg-surface--ne483693245{background-color:var(--surface--neutral--default);}
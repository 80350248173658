:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._gap-t-space-4{gap:var(--t-space-4);}
:root ._pb-0px{padding-bottom:0px;}
:root ._w-10037{width:100%;}
:root ._mt-auto{margin-top:auto;}
:root ._mr-auto{margin-right:auto;}
:root ._mb-auto{margin-bottom:auto;}
:root ._ml-auto{margin-left:auto;}
:root ._pt-t-space-8{padding-top:var(--t-space-8);}
:root ._pr-t-space-8{padding-right:var(--t-space-8);}
:root ._pb-t-space-1{padding-bottom:var(--t-space-1);}
:root ._pl-t-space-8{padding-left:var(--t-space-8);}
:root ._btw-1px{border-top-width:1px;}
:root ._brw-1px{border-right-width:1px;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._blw-1px{border-left-width:1px;}
:root ._btc-onSurface--1968954642{border-top-color:var(--onSurface--neutral--outlineAlt);}
:root ._brc-onSurface--1968954642{border-right-color:var(--onSurface--neutral--outlineAlt);}
:root ._bbc-onSurface--1968954642{border-bottom-color:var(--onSurface--neutral--outlineAlt);}
:root ._blc-onSurface--1968954642{border-left-color:var(--onSurface--neutral--outlineAlt);}
:root ._btlr-t-radius-1{border-top-left-radius:var(--t-radius-1);}
:root ._btrr-t-radius-1{border-top-right-radius:var(--t-radius-1);}
:root ._bbrr-t-radius-1{border-bottom-right-radius:var(--t-radius-1);}
:root ._bblr-t-radius-1{border-bottom-left-radius:var(--t-radius-1);}
:root ._bg-f8f8f835{background-color:#f8f8f8;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._pt-t-space-10{padding-top:var(--t-space-10);}
:root ._fg-0{flex-grow:0;}
:root ._ai-center{align-items:center;}
:root ._transform-translateY-1194849657{transform:translateY(-44px);}